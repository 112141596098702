import "@fancyapps/fancybox/dist/jquery.fancybox.js";
import AirDatepicker from "air-datepicker";
import localeEn from "air-datepicker/locale/en";
import AOS from "aos";
import $ from "jquery";
import Rellax from "rellax";
import "slick-carousel";

$(document).ready(function () {
  /* Menu */
  $("header .hamburger").click(function () {
    if ($("body").hasClass("open")) {
      $("body").removeClass("open");
    } else {
      $("body").addClass("open");
    }
  });

  /* Header Scroll */
  var didScroll;
  var lastScrollTop = 0;
  var delta = 5;
  var heroHeight = $("section.hero").outerHeight();
  var navbarHeight = heroHeight - 60;
  $(window).scroll(function (event) {
    didScroll = true;
  });
  setInterval(function () {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
  }, 250);
  function hasScrolled() {
    var st = $(window).scrollTop();
    if (Math.abs(lastScrollTop - st) <= delta) return;
    if (st > lastScrollTop && st > navbarHeight) {
      $("header").removeClass("nav-down").addClass("nav-up");
    } else {
      if (st + $(window).height() < $(document).height()) {
        $("header").removeClass("nav-up").addClass("nav-down");
      }
    }
    if (st <= navbarHeight) {
      $("header").removeClass("nav-up").removeClass("nav-down");
    }
    lastScrollTop = st;
  }

  $(".loaded-item").hide();
  $(".loaded-item").slice(0, 8).show();
  if ($(".loaded-item:hidden").length == 0) {
    $(".load-more").hide();
  }
  $(".load-more a").on("click", function (e) {
    e.preventDefault();
    $(".loaded-item:hidden").slice(0, 8).fadeIn(500);
    if ($(".loaded-item:hidden").length == 0) {
      $(".load-more").hide();
    }
  });

  $(".faqs .question").click(function (e) {
    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
    } else {
      $(this).addClass("active");
    }
  });

  var iframes = $(".hero-slider section");
  iframes.each(function (index, value) {});
  $(".hero-slider").on("init", function () {
    iframes
      .eq(0)
      .find("iframe")
      .attr("src", iframes.eq(0).find("iframe").data("src"));
  });
  $(".hero-slider").on(
    "beforeChange",
    function (event, slick, currentSlide, nextSlide) {
      iframes.eq(currentSlide).find("iframe").attr("src", "");
      iframes
        .eq(nextSlide)
        .find("iframe")
        .attr("src", iframes.eq(nextSlide).find("iframe").data("src"));
    }
  );
  $(".hero-slider").slick({
    autoplay: true,
    autoplaySpeed: 6000,
    dots: false,
    infinite: false,
    speed: 300,
    slidesPerRow: 1,
    nextArrow:
      '<span class="control next"><i class="fal fa-chevron-right"></i></span>',
    prevArrow:
      '<span class="control prev"><i class="fal fa-chevron-left"></i></span>',
  });

  $(".films").slick({
    rows: 2,
    dots: false,
    infinite: false,
    speed: 300,
    slidesPerRow: 4,
    nextArrow:
      '<span class="control next"><i class="fal fa-chevron-right"></i></span>',
    prevArrow:
      '<span class="control prev"><i class="fal fa-chevron-left"></i></span>',
    responsive: [
      {
        breakpoint: 959,
        settings: {
          dots: true,
          arrows: false,
          rows: 2,
          slidesPerRow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          dots: true,
          arrows: false,
          rows: 2,
          slidesPerRow: 2,
        },
      },
    ],
  });

  if ($("#map").length) {
    $("#map").googleMap({ markers: "div[data-lat]", recenter: "markers" });
  }

  /* Parallax */
  if ($(".rellax").length) {
    var rellax = new Rellax(".rellax", {
      center: true,
    });
  }

  $(".date").each(function () {
    new AirDatepicker(this, {
      locale: localeEn,
      dateFormat: "MMMM d, yyyy",
      autoClose: true,
      minDate: new Date(),
      onSelect() {
        $("#showtimes-form").submit();
      },
    });
  });

  /* Page animations */
  AOS.init({
    easing: "ease-out-back",
    duration: 1000,
  });
});

jQuery.fn.googleMap = function (options) {
  var settings = jQuery.extend(
    {
      center: new google.maps.LatLng(17.5739348, -3.9861092),
      map_type: google.maps.MapTypeId.ROADMAP,
      markers: false,
      zoom: 2,
      recenter: false,
    },
    options
  );
  this.each(function () {
    var map = new google.maps.Map(this, {
      center: settings.center,
      disableDefaultUI: false,
      mapTypeId: settings.map_type,
      scrollwheel: false,
      zoom: settings.zoom,
      maxZoom: 15,
    });
    var markers = [];
    $(settings.markers).each(function (index, el) {
      var dataLat = $(el).attr("data-lat");
      var dataLng = $(el).attr("data-lng");
      var popupContent = "";
      $(el)
        .parents()
        .find('div[data-lat="' + dataLat + '"][data-lng="' + dataLng + '"]')
        .each(function (index) {
          popupContent += $(this).html();
        });
      var marker = new google.maps.Marker({
        map: map,
        animation: google.maps.Animation.DROP,
        position: new google.maps.LatLng(
          $(el).attr("data-lat"),
          $(el).attr("data-lng")
        ),
      });
      var popup = new google.maps.InfoWindow({
        content: popupContent,
      });
      google.maps.event.addListener(marker, "click", function () {
        popup.open(map, marker);
      });
      markers.push(marker);
    });
    // var cluster = new MarkerClusterer(map, markers, {
    //   maxZoom: 16,
    // });
    if (settings.recenter == "markers") {
      var bounds = new google.maps.LatLngBounds();
      jQuery.each(markers, function (index, marker) {
        bounds.extend(marker.getPosition());
      });
      map.fitBounds(bounds);
    }
  });
};

// (function ($) {
//   $.fn.datepicker.language["en"] = {
//     days: [
//       "Sunday",
//       "Monday",
//       "Tuesday",
//       "Wednesday",
//       "Thursday",
//       "Friday",
//       "Saturday",
//     ],
//     daysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
//     daysMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
//     months: [
//       "January",
//       "February",
//       "March",
//       "April",
//       "May",
//       "June",
//       "July",
//       "August",
//       "September",
//       "October",
//       "November",
//       "December",
//     ],
//     monthsShort: [
//       "Jan",
//       "Feb",
//       "Mar",
//       "Apr",
//       "May",
//       "Jun",
//       "Jul",
//       "Aug",
//       "Sep",
//       "Oct",
//       "Nov",
//       "Dec",
//     ],
//     today: "Today",
//     clear: "Clear",
//     dateFormat: "mm/dd/yyyy",
//     timeFormat: "hh:ii aa",
//     firstDay: 0,
//   };
// })(jQuery);
